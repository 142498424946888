import Features from '@/components2/Features';
import { useMobxStores } from '@stores/index';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useState, Suspense } from 'react';
import { airportCarousel } from '@/utils/airportCarouselContent';
import AirportCarouselHome from '@/components2/AirportCarouselHome';
import AirportCarouselHomeB from '@/components2/AirportCarouselHomeB';
import { basicPageProps, mobileProps } from '@/utils/isServer';
import ModalDownloadApp from '@/components2/ModalDownloadApp';
import HomeSearchBC from '@/components/HomeSearchBC';
import ReviewsCarouselHome from '@/components2/ReviewsCarouselHome';
import { homeAbTest } from '@/utils/gaTools';
import Image from 'next/image';
import { CDN2 } from '@/helpers/api';
import Link from 'next/link';
import { Button } from 'antd';

const Airports = dynamic(() => import('@/components2/Airports'), {
  ssr: true,
});

const Guides = dynamic(() => import('@/components2/Guides'), {
  ssr: true,
});

const Monthlies = dynamic(() => import('@/components2/Monthlies'), {
  ssr: true,
});

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { isMobile, isIos } = mobileProps(ctx);
  const { airports } = await basicPageProps(ctx);

  return {
    props: {
      airports,
      deviceType: isMobile ? 'mobile' : 'desktop',
      mobileType: isIos ? 'ios' : 'android',
    },
  };
};

function Home({ airports, deviceType, mobileType }) {
  const { authStore, searchStore } = useMobxStores();
  const [loggedIn, setLoggedIn] = useState(false);
  const { uiStore } = useMobxStores();

  useEffect(() => {
    setLoggedIn(authStore.signedIn);
  }, [authStore, authStore.signedIn]);

  useEffect(() => {
    searchStore.clearAirportAndCityStorages();
  }, [searchStore]);

  // useEffect(() => {
  //   setTestScope(homeAbTest());
  // }, []);
  return (
    <div className="bg-white">
      <Head>
        <meta
          name="description"
          content="Find your local airport and reserve the cheapest airport parking through On Air Parking. We guarantee the lowest rates with the most professional facilities. Free shuttles and cancellations are always included. Book now!"
        />
        <title>
          Find your local airport and reserve the cheapest airport parking
          through On Air Parking. We guarantee the lowest rates with the most
          professional facilities. Free shuttles and cancellations are always
          included. Book now!
        </title>
      </Head>
      <HomeSearchBC airports={airports} deviceType={deviceType} />
      <Suspense>
        <div className="flex flex-col justify-center w-full py-4 px-4 md:!px-0 mx-auto bg-[#e86717]">
          <h2
            className={`text-2xl md:text-4xl text-center mt-8 md:mt-4 mb-4 font-bold text-white`}
          >
            Hey, College Students: Create the Ultimate Social Media Ad!
          </h2>
          {deviceType === 'desktop' ? (
            <Image
              quality={65}
              src={CDN2() + '/staticmyapp/contestad.webp'}
              blurDataURL={CDN2() + '/staticmyapp/contestad.webp'}
              alt="On Air Parking Ad contest"
              width={1000}
              height={400}
              className="self-center hidden md:flex"
            />
          ) : (
            <>
              <h2
                className={`md:hidden text-xl text-center mt-4 mb-4 font-bold text-white`}
              >
                Win a $1000 and an internship
              </h2>
              <p className="md:hidden text-center text-xl md:text-2xl mb-6 text-white">
                Create the ultimate 15-30 second ad targeting spring breakers
                (ages 20-30) who love saving money on airport parking.
              </p>
              <p className="md:hidden text-center text-xl md:text-2xl mb-6 text-white">
                More information:
              </p>
              <Button
                size="large"
                data-test-id="park_now"
                type="primary"
                block
                className="md:hidden  rounded-full text-lg font-semibold hover:bg-opacity-75 bg-white text-primary h-12 self-center mb-16"
              >
                <Link href="https://docs.google.com/forms/d/e/1FAIpQLScoOVerCgvljRTIj89ZK93C1qZ2Z1GcUdHzXou817QYqznwqg/viewform?usp=dialog">
                  Click here to enter
                </Link>
              </Button>
            </>
          )}
        </div>
      </Suspense>

      <div className="mx-auto px-auto bg-white  airports-slider">
        <div className={`${'bg-whyOnAir'}`}>
          <Features />
        </div>
        <Suspense>
          <div className="px-4 md:px-0">
            <AirportCarouselHomeB
              items={airportCarousel}
              deviceType={deviceType}
            />
            <ReviewsCarouselHome deviceType={deviceType} />
          </div>
        </Suspense>
      </div>
      <Suspense>
        {/* {deviceType === 'mobile' ? (
          <ModalDownloadApp deviceType={'mobile'} mobileType={mobileType} />
        ) : (
          <ModalDownloadApp />
        )} */}

        <h2 className="text-center text-2xl md:text-4xl mt-8 mb-4 font-bold">
          Discover More
        </h2>
        <p className="text-center text-xl md:text-2xl mb-6">
          Where you can park, Guides and Monthly Parking
        </p>

        <Airports airports={airports} />
        <Guides airports={airports} />
        <Monthlies airports={airports} />
      </Suspense>
    </div>
  );
}
Home.layout = 'DefaultLayout2';
export default Home;
